import React from 'react'
import Product from '../Product'
import useReveal from '../../hooks/useReveal'
import SVGgiphy from '../../svg/giphy/SVGgiphy'

import './styles.scss'

interface IProducts {
  name: string
  video: string
  thumbnail: string
  description: string
  price: string
  href: string
}

interface Isrcs {
  sm: string
  lg: string
}

interface ILogo {
  icon: Isrcs
  alt: string
}

interface IContent {
  name: string
  logo: ILogo
  title: string
  cta: string
  products: IProducts[]
}

const Collection: React.FC<IContent> = props => {
  const { ref, active, toggle, previewCount } = useReveal()

  return (
    <section className='collection'>
      <div className='collectionHead'>
        <div className='collectionLogo'>
          <SVGgiphy />
        </div>
        <div className='collectionHeadTxt'>
          <h2>{props.title}</h2>
        </div>
      </div>
      <div className='container'>
        <ul className='collectionGroup'>
          {props.products.slice(0, previewCount).map((el, idx) => (
            <div key={`${props.name}-${idx}`} className='product'>
              <Product
                name={el.name}
                video={el.video}
                thumbnail={el.thumbnail}
                description={el.description}
                price={el.price}
                href={el.href}
              />
            </div>
          ))}
        </ul>

        {active && (
          <div className='contents'>
            <ul className='collectionGroup'>
              {props.products
                .slice(previewCount, props.products.length)
                .map((el, idx) => (
                  <div key={`${props.name}-${idx}`} className='product'>
                    <Product
                      name={el.name}
                      video={el.video}
                      thumbnail={el.thumbnail}
                      description={el.description}
                      price={el.price}
                      href={el.href}
                    />
                  </div>
                ))}
            </ul>
          </div>
        )}
      </div>
      {!active && (
        <button ref={ref} className='inverse' onClick={toggle}>
          {props.cta}
        </button>
      )}
    </section>
  )
}

export default Collection
