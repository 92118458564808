import React from 'react'

import '../../styles/loader.scss'
import './styles.scss'

interface Isrcs {
  sm: string
  lg: string
}

interface IPress {
  name: string
  alt: string
  href: string
  logo: Isrcs
}

interface Iprivacy {
  txt: string
  href: string
}

interface ILegal {
  privacy: Iprivacy
  copyright: string
}

interface IContent {
  header: string
  press: IPress[]
  legal: ILegal
}

const Footer: React.FC<IContent> = props => {
  return (
    <footer className='footer'>
      <h3>{props.header}</h3>
      <ul className='press'>
        {props.press.map((el, idx) => (
          <li key={`${el.name}-${idx}`}>
            <a href={el.href} target='_blank' rel='noreferrer noopener'>
              <img
                src={el.logo.sm}
                srcSet={`${el.logo.sm} 1x, ${el.logo.lg} 2x`}
                alt={el.alt}
              />
            </a>
          </li>
        ))}
      </ul>

      <ul className='legal'>
        <div className='legalCol'>
          <a
            href={props.legal.privacy.href}
            target='_blank'
            rel='noreferrer noopener'
          >
            {props.legal.privacy.txt}
          </a>
        </div>
        <div className='legalCol'>
          <p>{props.legal.copyright}</p>
        </div>
      </ul>
    </footer>
  )
}

export default Footer
