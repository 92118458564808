import React from 'react'

const SVGpower: React.FC = props => {
  return (
    <svg width='77' viewBox='0 0 195 100' xmlns='http://www.w3.org/2000/svg'>
      <g fillRule='nonzero'>
        <path d='M121.56 75.529a3.177 3.177 0 01-3.172 3.172H24.91a3.177 3.177 0 01-3.174-3.172V25.193a3.177 3.177 0 013.174-3.175h93.48a3.178 3.178 0 013.171 3.175v50.336zm-3.172-58.466H24.91c-4.483 0-8.129 3.649-8.129 8.13v50.336c0 4.48 3.646 8.127 8.13 8.127h93.478c4.482 0 8.127-3.646 8.127-8.127V25.193c0-4.481-3.645-8.13-8.127-8.13z' />
        <path d='M79.372 49.05l-6-2.164 4.262-9.219c.266-.58.106-1.285-.38-1.665a1.154 1.154 0 00-1.58.138l-12.23 13.228c-.3.325-.422.794-.324 1.24.098.447.4.806.803.951l6 2.163-4.261 9.22c-.268.58-.107 1.285.379 1.665a1.156 1.156 0 001.58-.139L79.85 51.24c.299-.324.422-.793.326-1.239a1.29 1.29 0 00-.804-.95' />
        <path d='M190.76 48.858a2.248 2.248 0 01-2.248 2.248h-13.484a2.247 2.247 0 01-2.248-2.248V37.62h17.98v11.237zm-11.237 11.238h4.494V55.6h-4.494v4.495zm-41.183 30.92v4.06H4.956V4.958H134.28l4.06-.001v86.059zm54.666-57.89h-2.246v-8.99a2.249 2.249 0 00-4.495 0v8.99h-8.99v-8.99a2.248 2.248 0 10-4.495 0v8.99h-2.249a2.248 2.248 0 00-2.248 2.248v13.484a6.743 6.743 0 006.745 6.743v6.742a2.246 2.246 0 002.246 2.247h2.249v26.97h-16.69V12.9a2.249 2.249 0 00-2.249-2.248h-17.288V0l-9.016.002H9.013L0 0v100.03h143.297V15.146h15.042v78.662a2.248 2.248 0 002.246 2.248h21.185a2.248 2.248 0 002.248-2.248V64.59h2.248a2.246 2.246 0 002.247-2.247V55.6a6.742 6.742 0 006.743-6.743V35.374a2.248 2.248 0 00-2.249-2.248z' />
      </g>
    </svg>
  )
}

export default SVGpower
