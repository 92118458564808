import React from 'react'

// data
import data from './data/index.json'

// components
import Header from './components/Header'
import Collection from './components/Collection'
import DetailView from './components/DetailView'
import Footer from './components/Footer'

// styles
import './styles/loader.scss'

const App: React.FC = () => {
  return (
    <main className='App'>
      <Header logo={data.header.logo} tagline={data.header.tagline} />

      <ul>
        {data.collections.map((el, idx) => (
          <li key={`${el.name}-${idx}`}>
            <Collection
              name={el.name}
              logo={el.logo}
              title={el.title}
              cta={el.cta}
              products={el.products}
            />
          </li>
        ))}
      </ul>

      <DetailView
        header={data.detailView.header}
        subheader={data.detailView.subheader}
        video={data.detailView.video}
        cta={data.detailView.cta}
        featureHead={data.detailView.featureHead}
        features={data.detailView.features}
      />

      <Footer
        header={data.footer.header}
        press={data.footer.press}
        legal={data.footer.legal}
      />
    </main>
  )
}

export default App
