import React from 'react'

const SVGgiphyIcon: React.FC = props => {
  return (
    <svg
      viewBox='0 0 140 180'
      xmlns='http://www.w3.org/2000/svg'
      className="giphyIcon"
    >
      <g fill='none' fillRule='evenodd'>
        <path fill='#0F9' fillRule='nonzero' d='M0 16h20v148H0z' />
        <path fill='#93F' fillRule='nonzero' d='M120 56h20v108h-20z' />
        <path fill='#0CF' fillRule='nonzero' d='M0 160h140v20H0z' />
        <path fill='#FFF35C' fillRule='nonzero' d='M0 0h80v20H0z' />
        <path fill='#F66' d='M120 40V20h-20V0H80v60h60V40z' />
        <path fill='#0F0F0F' opacity='.4' d='M80 0v20H60zM120 80V60h20z' />
      </g>
    </svg>
  )
}

export default SVGgiphyIcon
