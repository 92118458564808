import React from 'react'

import './styles.scss'

interface IProduct {
  name: string
  video: string
  thumbnail: string
  description: string
  price: string
  href: string
}

const Product: React.FC<IProduct> = props => {
  return (
    <li className='productWrap'>
      <a href={props.href}>
        <div className='mediaWrapper'>
          <div className='mediaEl'>
            <img src={props.thumbnail} alt={props.description} />
            <video autoPlay loop muted playsInline src={props.video}></video>
          </div>
        </div>

        <div className='purchaseDetails'>
          <div className='productRow'>
            <h4>{props.name}</h4>

            <p>{props.price}</p>
          </div>
          <div className='productRow'>
            <h5>{props.description}</h5>
          </div>
        </div>
      </a>
    </li>
  )
}

export default Product
