import { useEffect, useState, useRef, useCallback } from 'react'
import useBreakpoint from './useBreakpoint'
import { Ref } from '../data/types/interfaces'

export default function useReveal() {
  const [active, setVisible] = useState(false)
  const [previewCount, setPreviewCount] = useState(1)

  const { brkPnt } = useBreakpoint()

  const elemReference: Ref = useRef(null)

  function toggle() {
    setVisible(!active)
  }

  const ref = useCallback(node => {
    elemReference.current = node
    if (elemReference && elemReference.current) setVisible(false)
  }, [])

  useEffect(() => {
    if (brkPnt === 'lg') setPreviewCount(3)
    else setPreviewCount(1)

    function handleClick() {
      if (elemReference && elemReference.current) setVisible(false)
    }

    if (active) document.addEventListener('mousedown', handleClick)
    else document.removeEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [active, brkPnt])

  return { ref, active, setVisible, toggle, previewCount }
}
