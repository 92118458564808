import React from 'react'
import './styles.scss'

interface Isrcs {
  sm: string
  lg: string
}

interface ILogo {
  icon: Isrcs
  alt: string
  href: string
}

interface Itagline {
  jumpTo: string
  clickable: string
  text: string
}

interface IContent {
  logo: ILogo
  tagline: Itagline
}

const Header: React.FC<IContent> = props => {
  return (
    <header className='header'>
      <a className='logo' href={props.logo.href}>
        <img
          src={props.logo.icon.sm}
          srcSet={`${props.logo.icon.sm} 1x, ${props.logo.icon.lg} 2x`}
          alt={props.logo.alt}
        />
      </a>
      <p className='tagline'>
        <a className='jumpTo' href={`#${props.tagline.jumpTo}`}>
          {props.tagline.clickable}
        </a>

        <span>{props.tagline.text}</span>
      </p>
    </header>
  )
}

export default Header
