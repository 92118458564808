import React from 'react'

const SVGgiphyText: React.FC = props => {
  return (
    <svg
      className='giphyText'
      viewBox='0 0 647 148'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fillRule='nonzero'>
        <path d='M108.124 45.461c-10.153-9.61-22.5-12.208-31.856-12.208-22.7 0-37.44 13.613-37.44 41.048 0 18.056 9.364 40.255 37.44 40.255 7.36 0 18.95-1.394 26.879-7.202V89.298H67.9V58.28H136v62.284C127.243 138.39 102.947 148 76.069 148 20.905 148 0 110.955 0 74.3 0 37.648 23.937 0 76.268 0c19.309 0 36.434 4.012 54.755 22.63l-22.9 22.831zM156 144V4h39v140zM258.536 106.203V144H219V4h67.89C324.438 4 343 27.602 343 55.001c0 28.601-18.763 50.8-56.11 51.202h-28.354zm0-33.4h28.355c11.002 0 16.573-8.014 16.573-17.2 0-9.187-5.791-17.602-16.573-17.602h-28.355v34.802zM447.84 144V90.995h-50.28V144H358V4h39.56v53.195h50.31V4H487v140zM572.4 51.996L602.523 4H647v1.793L591.532 88.2V144H552.06V88.2L499 5.593V4h44.488z' />
      </g>
    </svg>
  )
}

export default SVGgiphyText
