import React from 'react'
import SVGpower from '../../svg/SVGpower'
import SVGhandheld from '../../svg/SVGhandheld'

import './styles.scss'

interface IFeatures {
  name: string
  alt: string
  text: string
}

interface Icta {
  text: string
  href: string
}

interface Ivid {
  src: string
  thumb: string
  alt: string
}

interface IContent {
  header: string
  subheader: string
  video: Ivid
  cta: Icta
  featureHead: string
  features: IFeatures[]
}

const DetailView: React.FC<IContent> = props => {
  return (
    <section id='create' className='detailView'>
      <div className='detailViewHeader'>
        <h1>{props.header}</h1>
        <h6>{props.subheader}</h6>
        <a className='button' href={props.cta.href}>
          {props.cta.text}
        </a>
      </div>

      <div className='detailViewContent'>
        <div className='detailViewCol'>
          <div className='detailViewVideoWrapper'>
            <div className='detailViewVideoEl'>
              <img src={props.video.thumb} alt={props.video.alt}></img>
              <video
                autoPlay
                loop
                muted
                playsInline
                src={props.video.src}
              ></video>
            </div>
          </div>
        </div>
        <div className='detailViewCol'>
          <ul>
            <li>
              <h2>{props.featureHead}</h2>
            </li>
            {props.features.map((el, idx) => (
              <li key={`${el.name}-${idx}`}>
                {el.name === 'handheld' ? (
                  <SVGhandheld />
                ) : el.name === 'power' ? (
                  <SVGpower />
                ) : (
                  ''
                )}
                <h2>{el.text}</h2>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default DetailView
