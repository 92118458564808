import React from 'react'
import SVGgiphyText from './SVGgiphyText'
import SVGgiphyIcon from './SVGgiphyIcon'

import './styles.scss'

const SVGgiphy: React.FC = props => {
  return (
    <div className='giphyLogo'>
      <SVGgiphyIcon />
      <SVGgiphyText />
    </div>
  )
}

export default SVGgiphy
